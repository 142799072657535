.filter-sidebar {
  width: 20vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  z-index: 999;
  display: block;
  transition: 0.2s;
}
.filter-sidebar-close {
  width: 0px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  transition: 0.2s;
}

.layout-mask-filter {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
  z-index: 999;
  transition: all 0.3s ease;
}

.modal-in-filter {
  animation-name: modal-in-filter;
}

@keyframes modal-in-filter {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgb(0 0 0 / 24%);
  }
}

.filter-btn-close {
  padding: 18px;
  cursor: pointer;
  border-bottom: 1px solid #6366f12e;
}
.filter-btn-close > span {
  padding: 3px 9px 3px 9px;
  border-radius: 100%;
  background: #98a5da;
  color: #5c60ff;
}

.filter-heading {
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  letter-spacing: 1px;
}

/* overlay for dropdown for logout */

.overlay-dropdown {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
  /* z-index: 999; */
  transition: 0.3s;
}

.modal-in-drop {
  animation-name: modal-in-drop;
}

@keyframes modal-in-drop {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

.content-filter {
  height: 86vh;
  overflow: hidden;
  overflow-y: auto;
}
.content-filter::-webkit-scrollbar {
  width: 5px;
  border-radius: 100%;
}

/* Track */
.content-filter::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-filter::-webkit-scrollbar-thumb {
  background: #dee5fb;
  border-radius: 20px;
}

/* Handle on hover */
.content-filter::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.apply-btn-filter-bottom {
  bottom: 0px;
}

/* responcive filter silde bar  */

@media (max-width: 1440px) {
  .filter-sidebar {
    width: 35vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    z-index: 999;
    display: block;
    transition: 0.2s;
  }
}
@media (max-width: 768px) {
  .filter-sidebar {
    width: 50vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    z-index: 999;
    display: block;
    transition: 0.2s;
  }
}
@media (max-width: 425px) {
  .filter-sidebar {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
    z-index: 999;
    display: block;
    transition: 0.2s;
  }
}
