* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login_background {
  height: 100vh;
  width: 100vw;
  /* background-color: #c8d7e8; */
  background-color: #8183f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_main {
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh; */
}

.login_center_container {
  display: flex;
  justify-content: center;
  font-family: "Yantramanav", sans-serif;
}

.login_content_container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  /* width: 30vw; */
  border-radius: 6px;
  /* padding: 12px 20px 12px 20px; */
  padding: 4rem;
  /* box-shadow: 0px 0px 24px 5px rgba(72 72 72 / 54%); */
}

.header {
  font-family: "Yantramanav", sans-serif;
  margin-bottom: 1.5rem;
}
.header > div {
  font-size: 1.25rem;
  color: black;
  font-weight: 900;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}
.header > span {
  font-weight: 500;
  color: #757575;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 624px) {
  .login_center_container {
    display: flex;
    justify-content: center;
    font-family: "Yantramanav", sans-serif;
    margin: 12px;
  }
}
